.card-35    { background: #125593; }
.card-34    { background: #135C93; }
.card-33    { background: #146394; }
.card-32    { background: #146B94; }
.card-31    { background: #157295; }
.card-30    { background: #157995; }
.card-29    { background: #168196; }
.card-28    { background: #178896; }
.card-27    { background: #178F97; }
.card-26    { background: #189697; }
.card-25    { background: #199791; }
.card-24    { background: #19988B; }
.card-23    { background: #1A9885; }
.card-22    { background: #1B997F; }
.card-21    { background: #1C9978; }
.card-20    { background: #1C9A72; }
.card-19    { background: #1D9A6C; }
.card-18    { background: #229D67; }
.card-17    { background: #27A063; }
.card-16    { background: #2BA45E; }
.card-15    { background: #30A75A; }
.card-14    { background: #35AA56; }
.card-13    { background: #3AAD53; }
.card-12    { background: #3FB04F; }
.card-11    { background: #44B34C; }
.card-10    { background: #49B64A; }
.card-9     { background: #55B94E; }
.card-8     { background: #61BB53; }
.card-7     { background: #6DBE59; }
.card-6     { background: #79C15E; }
.card-5     { background: #84C463; }
.card-4     { background: #8FC668; }
.card-3     { background: #9AC96E; }